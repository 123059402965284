import { useEffect, useState } from 'react';

import { i18n } from '@lingui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { EditorContent, Node, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { useDebounce } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import Popover from './RichTextPopover';

const OneLiner = Node.create({
  name: 'oneLiner',
  topNode: true,
  content: 'block',
});

const getPrefixStyles = ({ prefix }) => {
  if (prefix) {
    const prefixLength =
      typeof prefix === 'number' ? `${prefix}`.length : prefix?.length;
    return `
        padding-left: ${1.7 + prefixLength * 0.8}rem;
        &::before {
          position: absolute;
          margin-left: -${(prefixLength + 1) * 0.8}rem;
          margin-top: 1px;
          content: '${prefix}.';
          white-space: pre;
          font-weight: ${svars.fontWeightMedium};
        }
    `;
  }
  return '';
};

const editorTextStyles = ({ light, strong, heading, centered }) => {
  let styles = '';
  if (light) {
    styles += `
      font-size: ${svars.fontSizeLarge};
      color: rgba(0, 0, 0, 0.5);
    `;
  } else if (heading) {
    styles += `

      font-size: ${svars.fontSizeBig};
      color: rgba(0, 0, 0, 0.8);
      `;
  } else if (strong) {
    styles += `

      font-size: ${svars.fontSizeXLarge};
      color: rgba(0, 0, 0, 0.8);
    `;
  } else {
    styles += `
      font-size: ${svars.fontSizeLarge};
      color: rgba(0, 0, 0, 0.7);
    `;
  }
  if (centered) {
    styles += 'text-align: center;';
  }
  return styles;
};

const StyledEditorContent = styled(EditorContent)`
  height: 100%;
  cursor: text;
  font-size: 40px;
  & div.ProseMirror {
    min-height: ${({ singleline, strong }) =>
      singleline && !strong ? '2rem' : '4rem'};
    height: 100%;
    padding: ${svars.spaceNormalLarge} ${svars.spaceMedium};
    ${getPrefixStyles}
    ${editorTextStyles}
    ${({ subtitle, title }) =>
      (subtitle && `padding-top: ${svars.spaceSmaller};`) ||
      (title && `padding-bottom: ${svars.spaceSmaller};`) ||
      ''}
    outline: none;
    background: ${({ active }) => (active ? '#f5f5f5' : '#fbfbfb')};
    &:hover {
      background: ${({ active }) => (active ? '#f5f5f5' : '#f6f6f6')};
    }
    overflow-y: auto;
    & .is-editor-empty:first-child::before {
      color: #adb5bd;
      content: attr(data-placeholder);
      float: ${({ centered }) => (centered ? 'center' : 'left')};
      height: 0;
      pointer-events: none;
    }
  }
`;

function RichTextEditor({
  content,
  onChange,
  editable,
  placeholder,
  withLink,
  withTextAlign,
  withHeading,
  withCodeBlock,
  basic,
  light,
  strong,
  heading,
  centered,
  prefix,
  isSubtitle,
  hasSubtitle,
  singleLine,
  style,
}) {
  const [currentContent, setCurrentContent] = useState(content);

  const debouncedCurrentContent = useDebounce(currentContent, 600);

  useEffect(() => {
    if (content !== debouncedCurrentContent) {
      onChange(debouncedCurrentContent);
    }
  }, [debouncedCurrentContent, content, onChange]);
  const extensions = singleLine
    ? [
        OneLiner,
        StarterKit.configure({
          ...{ codeBlock: false, hardBreak: false },
        }),
      ]
    : [
        StarterKit.configure({
          ...{ codeBlock: false },
        }),
      ];
  if (!basic) {
    extensions.push(Typography);
    extensions.push(Underline);
  }

  if (withLink) {
    extensions.push(
      Link.extend({ inclusive: false }).configure({
        linkOnPaste: false,
        openOnClick: false,
      })
    );
  }

  if (placeholder) {
    extensions.push(
      Placeholder.configure({
        placeholder: i18n._(placeholder),
        considerAnyAsEmpty: true,
        showOnlyCurrent: false,
      })
    );
  }
  if (withTextAlign) {
    extensions.push(
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      })
    );
  }
  const editor = useEditor(
    {
      content,
      // NB : autofocus is not working with the current version of tiptap
      // Besides not working, it generates an implicit scroll to the element, affecting
      // other containers in the page
      // autofocus: true,
      extensions,
      editable,
      onUpdate: ({ editor: editorToUpdate }) => {
        setCurrentContent(editorToUpdate?.[basic ? 'getText' : 'getHTML']());
      },
      editorProps: {
        attributes: {
          class: 'heading',
        },
      },
    },
    [editable]
  );

  return (
    <div
      style={{
        width: '100%',
        overflow: 'auto',
        minHeight: ((strong || !singleLine) && '4rem') || '2rem',
        opacity: editor ? 1 : 0,
        transition: 'opacity 0.4s ease-in-out',
        ...style,
      }}
    >
      {editor ? (
        <>
          {!basic ? (
            <Popover
              editor={editor}
              withTextAlign={withTextAlign}
              withLink={withLink}
              withHeading={withHeading}
              withCodeBlock={withCodeBlock}
            />
          ) : null}

          <StyledEditorContent
            data-testid="bo-campaign-customize-input-placeholder"
            subtitle={isSubtitle ? 'true' : null}
            title={hasSubtitle ? 'true' : null}
            light={light ? 'true' : null}
            heading={heading ? 'true' : null}
            strong={strong ? 'true' : null}
            singleline={singleLine ? 'true' : null}
            centered={centered ? 'true' : null}
            prefix={prefix}
            active={editor?.isFocused ? 'true' : null}
            key="rich-text-input"
            editor={editor}
          />
        </>
      ) : (
        <StyledEditorContent />
      )}
    </div>
  );
}

RichTextEditor.propTypes = {
  content: PropTypes.string,
  editable: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  // Extensions
  withTextAlign: PropTypes.bool,
  withLink: PropTypes.bool,
  withHeading: PropTypes.bool,
  withCodeBlock: PropTypes.bool,
  // Set to retrieve basic text instead of html
  basic: PropTypes.bool,
  // Set to use a smaller font size and lighter color
  light: PropTypes.bool,
  // Set to use a larger font size and stronger color
  strong: PropTypes.bool,
  // Set to use a larger font size and stronger color
  heading: PropTypes.bool,
  // Set to center the content
  centered: PropTypes.bool,
  // Set to add a prefix to the content
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // Set to remove padding from the top of the editor
  isSubtitle: PropTypes.bool,
  // Set to add padding to the bottom of the editor
  hasSubtitle: PropTypes.bool,
  // Set to use a single line editor
  singleLine: PropTypes.bool,
  style: PropTypes.shape({}),
};

RichTextEditor.defaultProps = {
  content: '',
  editable: true,
  placeholder: null,
  withLink: false,
  withTextAlign: false,
  withHeading: false,
  withCodeBlock: false,
  basic: false,
  light: false,
  strong: false,
  heading: false,
  centered: false,
  prefix: null,
  isSubtitle: false,
  hasSubtitle: false,
  singleLine: false,
  style: {},
};

export default RichTextEditor;
