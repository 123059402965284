import React, { useCallback } from 'react';

import { Trans, t, msg } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import { Editor } from '@tiptap/react';

import { validateUrl } from 'utils/dataValidation';

import * as svars from 'assets/style/variables';

import {
  AnalyticsAwareHoverableIconButtonWithTooltip,
  HoverableIcon,
} from '../icon/HoverableIcon';

const STATIC_CLICKABLE_ELEMENTS = [
  { name: 'bold', icon: 'bold', action: 'toggleBold', help: msg({ id: "bold-ctrl-b" }) },
  {
    name: 'italic',
    icon: 'italic',
    action: 'toggleItalic',
    help: msg({ id: "italic-ctrl-i" }),
  },
  {
    name: 'strike',
    icon: 'strikethrough',
    action: 'toggleStrike',
    help: msg({ id: "strikethrough-ctrl-shift-s" }),
  },
  {
    name: 'underline',
    icon: 'underline',
    action: 'toggleUnderline',
  },
];

const TEXT_ALIGN_ELEMENTS = [
  {
    key: 'ta-left',
    name: 'textAlign',
    icon: 'align left',
    action: 'setTextAlign',
    actionParams: 'left',
    activeCheck: { textAlign: 'left' },
  },
  {
    key: 'ta-center',
    name: 'textAlign',
    icon: 'align center',
    action: 'setTextAlign',
    actionParams: 'center',
    activeCheck: { textAlign: 'center' },
  },
  {
    key: 'ta-right',
    name: 'textAlign',
    icon: 'align right',
    action: 'setTextAlign',
    actionParams: 'right',
    activeCheck: { textAlign: 'right' },
  },
];

const HEADING_ELEMENT = {
  name: 'heading',
  icon: 'header',
  action: 'toggleHeading',
  actionParams: { level: 2 },
};

const CODE_BLOCK_ELEMENT = { name: 'code', icon: 'code', action: 'toggleCode' };

const getClickableElements = (withTextAlign, withHeading, withCodeBlock) => {
  const clickableElements = [...STATIC_CLICKABLE_ELEMENTS];
  if (withTextAlign) {
    clickableElements.push(...TEXT_ALIGN_ELEMENTS);
  }
  if (withHeading) {
    clickableElements.push(HEADING_ELEMENT);
  }
  if (withCodeBlock) {
    clickableElements.push(CODE_BLOCK_ELEMENT);
  }
  return clickableElements;
};

export function RichTextLinkEdition({ editor, onClose }) {
  const link = editor.getAttributes('link');
  const url = link.href;
  const [newUrl, setNewUrl] = React.useState(url);
  const [error, setError] = React.useState(null);

  const onValidateLink = useCallback(() => {
    if (!newUrl) {
      setError(t({ id: "url-cannot-be-empty" }));
      return;
    }
    // Add https:// to saved url if not present
    const toSave = newUrl.match(/^https?:\/\//) ? newUrl : `https://${newUrl}`;
    // Validate url format with regex
    if (!validateUrl(toSave)) {
      setError(t({ id: "url-invalid" }));
      return;
    }

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: toSave })
      .run();
    onClose();
  }, [editor, newUrl, onClose]);

  const removeLink = () => {
    editor.chain().focus().extendMarkRange('link').unsetLink().run();
    onClose();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span style={{ display: 'inline-flex' }}>
        <input
          type="text"
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
          placeholder={t({ id: "enter-an-url" })}
        />
        <PopoverActionButton type="button" onClick={onValidateLink}>
          <Icon name="check" />
        </PopoverActionButton>
        <PopoverActionButton type="button" onClick={removeLink}>
          <Icon name="delete" />
        </PopoverActionButton>
      </span>
      {error ? <div>{error}</div> : null}
    </div>
  );
}

RichTextLinkEdition.propTypes = {
  editor: PropTypes.instanceOf(Editor).isRequired,
  onClose: PropTypes.func.isRequired,
};

export function ClickableElement({ onClick, icon, size, active, help }) {
  return (
    <AnalyticsAwareHoverableIconButtonWithTooltip
      help={help ? <Trans id={help} /> : null}
      mouseEnterDelay={400}
      mouseLeaveDelay={250}
      active={active ? 'true' : null}
      trigger={
        <div>
          <HoverableIcon
            onClick={onClick}
            size={size}
            active={active ? 'true' : null}
            name={icon}
          />
        </div>
      }
    />
  );
}

ClickableElement.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  active: PropTypes.bool,
  help: PropTypes.string,
};

ClickableElement.defaultProps = { size: null, active: false, help: null };

export const PopoverActionButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  font-size: {svars.fontSizeLarge};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.2s;

  &&& i {
    color: ${(props) =>
      props.active ? svars.accentColorLight : svars.colorWhite};
  }
`;

export function RichTextPopoverContent({
  editor,
  onClickAction,
  linkPopoverVisible,
  setRichTextLinkEditionVisible,
  withTextAlign,
  withHeading,
  withCodeBlock,
  withLink,
}) {
  return (
    <>
      {getClickableElements(withTextAlign, withHeading, withCodeBlock).map(
        ({ key, name, icon, action, actionParams, size, activeCheck }) => (
          <PopoverActionButton
            key={key || name}
            active={editor.isActive(activeCheck || name) ? 'true' : null}
            type="button"
            onClick={onClickAction(editor, action, actionParams)}
            size={size}
            name={icon}
          >
            <Icon name={icon} />
          </PopoverActionButton>
        )
      )}
      {withLink ? (
        <ClickableElement
          icon="linkify"
          active={!!editor.getAttributes('link').href}
          onClick={() => setRichTextLinkEditionVisible(!linkPopoverVisible)}
        />
      ) : null}
    </>
  );
}

RichTextPopoverContent.propTypes = {
  editor: PropTypes.instanceOf(Editor).isRequired,
  onClickAction: PropTypes.func.isRequired,
  linkPopoverVisible: PropTypes.bool.isRequired,
  setRichTextLinkEditionVisible: PropTypes.func.isRequired,
  withTextAlign: PropTypes.bool,
  withHeading: PropTypes.bool,
  withCodeBlock: PropTypes.bool,
  withLink: PropTypes.bool,
};

RichTextPopoverContent.defaultProps = {
  withTextAlign: false,
  withHeading: false,
  withCodeBlock: false,
  withLink: false,
};
