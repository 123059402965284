import { useCallback, useState } from 'react';

import { useLingui } from '@lingui/react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import DashboardBlock, {
  computeMinimumWidth,
} from 'components/ui/dashboard/DashboardBlock';

import * as svars from 'assets/style/variables';

const DashboardContainer = styled.div`
  padding: ${svars.spaceNormal};
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-basis: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;

const responsiveLayout = (rows, dashboardWidth) => {
  let responsiveRows = [];
  rows.forEach((row) => {
    const rowMinWidth = computeMinimumWidth(row);
    if (rowMinWidth > dashboardWidth) {
      const firstItemsRows = responsiveLayout(
        [
          {
            ...row,
            children: row.children.slice(0, row.children.length - 1),
          },
        ],
        dashboardWidth
      );
      responsiveRows = [
        ...responsiveRows,
        ...firstItemsRows,
        ...[
          {
            ...row,
            row: firstItemsRows[firstItemsRows.length - 1].row + 1,
            children: [row.children[row.children.length - 1]],
          },
        ],
      ];
    } else {
      responsiveRows.push(row);
    }
  });
  return responsiveRows;
};

function Dashboard({ elements, getExportTitle, loading }) {
  useLingui();
  const [responsiveElements, setResponsiveElements] = useState([]);
  const dashboardRef = useCallback((node) => {
    if (node !== null) {
      setResponsiveElements(
        responsiveLayout(elements, node.getBoundingClientRect().width)
      );
    }
  }, []);
  return (
    <DashboardContainer ref={dashboardRef}>
      {responsiveElements.map((element, i) => (
        <DashboardBlock
          getExportTitle={getExportTitle}
          loading={loading}
          // eslint-disable-next-line react/no-array-index-key
          key={`edbs-${element.row}-${i}`}
          {...element}
        />
      ))}
    </DashboardContainer>
  );
}

Dashboard.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.shape(DashboardBlock.propTypes))
    .isRequired,
  getExportTitle: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
Dashboard.defaultProps = {
  loading: false,
};

export default Dashboard;
