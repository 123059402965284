import {
  CheckboxListFactory,
  CheckboxListItems,
  baseListItemProps,
  selectAllItems,
} from './BaseCheckboxList';
import {
  HierarchicalCheckboxListItems,
  onSelectAllHierarchicalFilteredItems,
} from './HierarchicalCheckboxList';

const CheckboxList = CheckboxListFactory(CheckboxListItems, selectAllItems);
const HierarchicalCheckboxList = CheckboxListFactory(
  HierarchicalCheckboxListItems,
  onSelectAllHierarchicalFilteredItems
);

export { HierarchicalCheckboxList, baseListItemProps };

export default CheckboxList;
