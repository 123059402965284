import { Component } from 'react';

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonSecondaryAccent } from 'components/ui/button';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import { spaceNormal, spaceSmall } from 'assets/style/variables';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ centered }) => (centered ? 'center' : 'flext-start')};
`;

class ButtonGroup extends Component {
  componentDidMount() {
    const { items, onChange } = this.props;
    if (items && items.length === 1) onChange(null, items[0]);
  }

  render() {
    const {
      items,
      value,
      onChange,
      as,
      centered,
      disabled,
      style,
      buttonBottomPadded,
      areValuesEqual,
      testid,
    } = this.props;
    const ButtonComponent = as;
    const nItems = items.length;
    return (
      <Container centered={centered} style={style}>
        {items.map((item, i) => (
          <ButtonComponent
            key={item.key}
            onClick={(e) => onChange(e, item)}
            active={areValuesEqual(item.value, value)}
            style={{
              margin: `0 ${i === nItems - 1 ? '0' : spaceSmall} ${
                buttonBottomPadded ? spaceNormal : '0'
              } ${i === 0 ? '0' : spaceSmall}`,
            }}
            icon={item.icon}
            labelPosition={item.labelPosition}
            content={
              (item.i18nLabel && (
                <Trans render={capitalizedTranslation} id={item.i18nLabel} />
              )) ||
              item.label ||
              item.text
            }
            disabled={disabled}
            data-testid={`${testid}-${i}`}
          />
        ))}
      </Container>
    );
  }
}

const valuePropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.object,
  PropTypes.array,
  PropTypes.bool,
]);
ButtonGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      i18nLabel: commonPropTypes.i18nText,
      // We can use object value to map multiple fields to a given value
      value: valuePropTypes,
    })
  ).isRequired,
  areValuesEqual: PropTypes.func,
  value: valuePropTypes,
  onChange: PropTypes.func.isRequired,
  as: commonPropTypes.component,
  centered: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonBottomPadded: PropTypes.bool,
  style: commonPropTypes.style,
  testid: PropTypes.string,
};

ButtonGroup.defaultProps = {
  value: '',
  areValuesEqual: (value1, value2) => value1 === value2,
  as: ButtonSecondaryAccent,
  centered: false,
  disabled: false,
  buttonBottomPadded: false,
  style: {},
  testid: undefined,
};

export default ButtonGroup;
