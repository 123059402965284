import { Trans } from '@lingui/macro';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';

import * as svars from 'assets/style/variables';

export const StrongShadowDropdown = styled(Dropdown)`
  &&&& {
    & div.menu {
      box-shadow: ${svars.strongBoxShadow};
    }
  }
`;

export const StyledDropdown = styled(StrongShadowDropdown)`
  &&&& {
    display: inline-flex;
    align-items: center;
    max-width: calc(100% - ${svars.spaceSmall});
    transition: ${svars.transitionBase};
    border-radius: ${svars.ctaBorderRadius};
    padding: ${svars.spaceSmaller};
    margin: 0 ${svars.spaceSmall};

    & div.menu {
      max-width: ${(props) => (props.fluid ? 'none' : '100%')};
      margin-top: ${svars.spaceNormalLarge};
      border-radius: ${svars.borderRadius};
      width: 100%;
      max-height: 270px;
      overflow-y: auto;
    }

    & div.default.text {
      color: ${svars.fontColorBase};
    }
    & div.text,
    i {
      color: ${({ basic }) => (basic ? 'inherit' : svars.accentColor)};
      overflow-x: clip;
      overflow-y: hidden;
      text-overflow: ellipsis;
      user-select: none;
    }
    & i {
      margin-left: ${svars.spaceNormal};
      line-height: 1;
      flex-shrink: 0;
    }
    & div > div {
      max-width: ${(props) => (props.fluid ? 'none' : '600px')};
      min-width: ${(props) => (props.fluid ? 'none' : svars.inputMinWidth)};
      color: ${svars.fontColorBase};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      background: ${svars.accentColorTransparent};
      color: ${svars.accentColorHover};
      & div.text,
      i,
      span > a {
        color: ${svars.accentColorHover};
      }
      & svg > path {
        fill: ${svars.accentColorHover};
      }
    }
  }
`;

function InTextDropdown({ basic, options, style, disabled, icon, ...props }) {
  const finalDisabled = disabled || options?.length === 0;
  const formattedOptions = (options || []).map(({ i18nText, ...item }) => {
    const isSelected = item.value === props.value;
    return {
      ...item,
      ...(i18nText
        ? {
            text: <Trans id={i18nText} />,
          }
        : {}),
      style: {
        ...(item.style || {}),
        color: isSelected ? svars.accentColor : svars.fontColorBase,
      },
    };
  });
  return (
    <StyledDropdown
      basic={basic}
      labeled
      icon={icon || 'sort'}
      style={{ ...style, whiteSpace: 'pre' }}
      disabled={finalDisabled}
      // Remove autogenerated i18n prop to avoid passing it to DOM element
      {...omit(props, ['i18nHash', 'disabled'])}
      options={formattedOptions}
    />
  );
}

InTextDropdown.propTypes = {
  // If set, inherit color
  basic: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
      i18nText: commonPropTypes.i18nText,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
};

InTextDropdown.defaultProps = {
  style: {},
  disabled: false,
  basic: false,
  icon: null,
};

export default InTextDropdown;
