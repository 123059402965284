import PropTypes from 'prop-types';
import { Icon, IconGroup, Loader } from 'semantic-ui-react';

import HelpTooltip from 'components/ui/HelpTooltip';

import withClickAnalytics from 'utils/withAnalytics';

import HoverableIcon, {
  HoverableIconButtonContainer,
  LabelContainer,
} from './HoverableIcon';

export function HoverableIconButtonWithBadge({
  name,
  size,
  style,
  loading,
  label,
  showBadge,
  ...props
}) {
  return (
    <HoverableIconButtonContainer {...props} style={style}>
      {loading ? <Loader inline active size="small" /> : null}
      {!loading && name ? (
        <IconGroup style={{ position: 'relative', display: 'inline-block' }}>
          <Icon name={name} size={size} />
          {showBadge && (
            <Icon
              corner="top right"
              name="circle"
              color="red"
              style={{
                position: 'absolute',
                top: '0px',
                right: '-3px',
              }}
              size="tiny"
            />
          )}
        </IconGroup>
      ) : null}
      {label ? <LabelContainer>{label}</LabelContainer> : null}
    </HoverableIconButtonContainer>
  );
}

HoverableIconButtonWithBadge.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  accent: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  style: PropTypes.shape(),
  loading: PropTypes.bool,
  showBadge: PropTypes.bool,
};

HoverableIconButtonWithBadge.defaultProps = {
  size: null,
  accent: 'true',
  style: {},
  loading: false,
  label: null,
  showBadge: false,
};

export function HoverableIconButtonWithBadgeWithTooltip({
  help,
  style,
  active,
  inverted,
  tooltipInverted,
  position,
  mouseEnterDelay,
  mouseLeaveDelay,
  trigger,
  showTooltipOn,
  disabled,
  disabledHelp,
  ...hoverableIconProps
}) {
  const finalHelp = disabled ? disabledHelp || null : help;
  const finalTrigger = trigger || (
    <HoverableIconButtonWithBadge
      style={style}
      active={active ? 'true' : undefined}
      inverted={inverted ? 'true' : undefined}
      disabled={disabled}
      {...hoverableIconProps}
    />
  );
  return (
    <HelpTooltip
      on={showTooltipOn}
      help={[finalHelp]}
      compact
      size="tiny"
      inverted={tooltipInverted}
      position={position}
      disabled={!help || (disabled && !disabledHelp)}
      trigger={
        disabled && disabledHelp ? <span>{finalTrigger}</span> : finalTrigger
      }
      baseColor
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
    />
  );
}

HoverableIconButtonWithBadgeWithTooltip.propTypes = {
  ...HoverableIcon.propTypes,
  help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  position: PropTypes.string,
  mouseEnterDelay: PropTypes.number,
  mouseLeaveDelay: PropTypes.number,
  showTooltipOn: PropTypes.oneOf(['hover', 'click']),
  tooltipInverted: PropTypes.bool,
};
HoverableIconButtonWithBadgeWithTooltip.defaultProps = {
  position: undefined,
  mouseEnterDelay: 800,
  mouseLeaveDelay: 200,
  help: null,
  showTooltipOn: 'hover',
  tooltipInverted: true,
};

export const AnalyticsAwareHoverableIconButtonWithBadgeWithTooltip =
  withClickAnalytics(HoverableIconButtonWithBadgeWithTooltip, 'onClick');

export default HoverableIcon;
