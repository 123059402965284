import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import { addFaqItem, updateFaqItem } from 'actions/campaign';

import Link from '@tiptap/extension-link';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import Header from 'components/ui/Header';
import { ButtonAccent, ButtonTransparentDanger } from 'components/ui/button';
import RichTextEditor from 'components/ui/inputs/RichTextEditor';
import { LimitedTextInput } from 'components/ui/inputs/TextInput';

import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

function FeedbackAppFaqItemModal({
  campaignId,
  create,
  faqItem,
  open,
  onClose,
}) {
  const dispatch = useDispatch();
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        code: false,
        codeBlock: false,
        history: false,
        strike: false,
      }),
      Link,
    ],
    content: '',
  });
  const [header, setHeader] = useState('');
  const [content, setContent] = useState('');
  const emptyValues = useCallback(() => {
    editor.commands.setContent('');
    setHeader('');
  }, [editor]);
  const onSubmit = useCallback(() => {
    // const HTMLString = editor.getHTML();
    if (create) {
      dispatch(addFaqItem(campaignId, header, content));
    } else {
      dispatch(
        updateFaqItem(campaignId, faqItem.id, faqItem.position, {
          content,
          header,
        })
      );
    }
    onClose();
    emptyValues();
  }, [editor, campaignId, faqItem?.id, faqItem?.position, header, content]);
  useEffect(() => {
    if (faqItem) {
      setHeader(faqItem.header);
      setContent(faqItem.content);
    }
  }, [faqItem]);

  return (
    <Modal closeIcon open={open} onClose={onClose}>
      <Modal.Header
        content={create ? t({ id: "create-faq-item" }) : t({ id: "edit-faq-section" })}
      />
      <Modal.Content>
        <Header
          content="Title"
          style={{
            marginTop: svars.spaceMedium,
            marginBottom: svars.spaceNormal,
          }}
        />
        <div style={{ margin: svars.spaceNormal }}>
          <LimitedTextInput
            value={header}
            onChange={(event) => setHeader(event.target.value)}
            maxCharacters={50}
          />
        </div>
        <Header
          content={<Trans id="edit-response" />}
          style={{
            marginTop: svars.spaceMedium,
            marginBottom: svars.spaceNormal,
          }}
        />
        <div style={{ margin: svars.spaceNormal }}>
          {editor !== null && (
            <RichTextEditor
              content={content}
              onChange={setContent}
              placeholder={t({ id: "faq-answer-placeholder" })}
              withTextAlign
              withLink
            />
          )}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <ButtonTransparentDanger
          onClick={() => {
            onClose();
            emptyValues();
          }}
        >
          <Trans render={capitalizedTranslation} id="cancel" />
        </ButtonTransparentDanger>
        {editor && (
          <ButtonAccent
            type="submit"
            disabled={!header || content === '<p></p>'}
            onClick={onSubmit}
          >
            <Trans id={create ? t({ id: "create-faq-item" }) : t({ id: "save-changes" })} />
          </ButtonAccent>
        )}
      </Modal.Actions>
    </Modal>
  );
}

FeedbackAppFaqItemModal.propTypes = {
  campaignId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  create: PropTypes.bool,
  faqItem: PropTypes.shape({
    id: PropTypes.string,
    position: PropTypes.number,
    header: PropTypes.string,
    content: PropTypes.string,
  }),
};

FeedbackAppFaqItemModal.defaultProps = {
  create: false,
  faqItem: null,
};

export default FeedbackAppFaqItemModal;
