import { useCallback, useMemo, useState } from 'react';

import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import PropTypes from 'prop-types';
import { Accordion, Icon, List } from 'semantic-ui-react';
import styled from 'styled-components';

import { Checkbox } from 'components/ui/inputs/Checkbox';

import * as svars from 'assets/style/variables';

const TAB_SIZE = 11;

const isHierarchicalParent = (item) => !!item.children?.length;

/**
 * Add remaining nodes from `itemChildren` map to baseItems in a hierarchical manner, recursively.
 *
 * @param {Object} itemChildren A mapping of parent key to array of children keys.
 * @param {Object} itemMap A mapping of item key to item.
 * @param {Array<Object>} baseItems A list of items.
 * @param {Object} addNonRooted Whether to add nodes from itemChildren even if their parent is not present in `baseItems`.
 */
export const addNested = (itemMap, itemChildren) => {
  const addNestedNodes = (baseItems, addNonRooted = true) => {
    // Rooted items are the ones that have a root ancestor (i.e. an ancestor with no parent)
    const rootedItems = baseItems.map((item) => {
      const childrenKeys = itemChildren[item.key];
      if (childrenKeys) {
        delete itemChildren[item.key];
        return {
          ...item,
          children: addNestedNodes(
            childrenKeys.map((itemKey) => itemMap[itemKey]),
            false
          ),
        };
      }
      return item;
    });

    let items;
    if (addNonRooted) {
      // Add non rooted items
      const nonRootedItemsMap = {};
      Object.entries(itemChildren).forEach(([parentKey, childrenKeys]) => {
        const parentItem = itemMap[parentKey];
        const children = addNestedNodes(
          childrenKeys.map((itemKey) => {
            // If already added in non rooted items (possible depending on item order), pop it and use it as child item here
            const childItem = nonRootedItemsMap[itemKey];
            if (childItem) {
              delete nonRootedItemsMap[itemKey];
              return childItem;
            }
            // If not, return simple item (with no children field) from item map
            return itemMap[itemKey];
          }),
          false
        );
        if (parentItem) {
          // If parent is also present, use it to nest the children
          nonRootedItemsMap[parentKey] = {
            ...itemMap[parentKey],
            children,
          };
        } else {
          // If parent is absent, show the children as roots
          children.forEach((child) => {
            nonRootedItemsMap[child.key] = child;
          });
        }
      });
      items = [...rootedItems, ...Object.values(nonRootedItemsMap)];
    } else {
      items = rootedItems;
    }
    return sortBy(items, ['label']);
  };
  return addNestedNodes;
};

const countLeafItems = (item) =>
  !item.children ? 1 : sum(item.children.map(countLeafItems));

/**
 * Nest a flat list of items based on their parent-child relationships.
 *
 * @param {Array} items - The flat list of items to be nested.
 * @param {Function} getItemParent - A function that takes an item and returns its parent key.
 * @returns {Array} - The nested list of items.
 */
const nestItems = (items, getItemParent) => {
  const itemChildren = {};
  const itemMap = {};
  const nestedItems = [];
  items.forEach((item) => {
    const parent = getItemParent(item);
    if (parent) {
      itemChildren[parent] = itemChildren[parent]
        ? [...itemChildren[parent], item.key]
        : [item.key];
    } else {
      nestedItems.push(item);
    }
    itemMap[item.key] = item;
  });
  return addNested(itemMap, itemChildren)(nestedItems);
};

function NestedItems({
  items,
  disabled,
  activeKeys,
  selectedItems,
  isSelectedItem,
  isSelectedParent,
  marginLeft = 0,
  onItemClick,
}) {
  return (
    <List selection verticalAlign="middle" style={{ height: '100%' }}>
      {items.map((item) => {
        let content = null;
        if (isHierarchicalParent(item)) {
          const isItemActive = activeKeys.includes(item.key);
          content = (
            <HierarchicalParentNode
              disabled={disabled}
              item={item}
              activeKeys={activeKeys}
              isItemActive={isItemActive}
              isItemSelected={isSelectedParent(item)}
              marginLeft={marginLeft}
              onItemClick={onItemClick}
              selectedItems={selectedItems}
              isSelectedItem={isSelectedItem}
              isSelectedParent={isSelectedParent}
            />
          );
        } else {
          const isItemSelected = isSelectedItem(item, selectedItems);
          content = (
            <Checkbox
              disabled={disabled}
              checked={isItemSelected}
              onClick={onItemClick(item, false, isItemSelected)}
              label={item.label}
              data-testid="bo-select-element-check-button"
            />
          );
        }
        return (
          <List.Item
            style={{
              marginLeft: `${(marginLeft && TAB_SIZE) || 0}px`,
              padding: svars.spaceNormal,
            }}
            key={`lii-${item.key}`}
          >
            {content}
          </List.Item>
        );
      })}
    </List>
  );
}

NestedItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  activeKeys: PropTypes.arrayOf(PropTypes.string),
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
  ),
  isSelectedItem: PropTypes.func,
  isSelectedParent: PropTypes.func,
  marginLeft: PropTypes.number,
  onItemClick: PropTypes.func,
};

NestedItems.defaultProps = {
  items: [],
  disabled: false,
  activeKeys: [],
  selectedItems: [],
  isSelectedItem: () => false,
  isSelectedParent: () => false,
  marginLeft: 0,
  onItemClick: () => null,
};

function NestedCheckboxLabel({ item }) {
  const nChildren = countLeafItems(item);
  return (
    <span
      key={`nl-${item?.key}`}
      style={{
        color: svars.fontColorBase,
        fontWeight: svars.fontWeightMedium,
      }}
    >
      {item?.label}
      <span
        style={{
          color: svars.fontColorLighter,
          fontStyle: 'italic',
        }}
      >
        {` (${nChildren} élément(s))`}
      </span>
    </span>
  );
}

NestedCheckboxLabel.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
};
NestedCheckboxLabel.defaultProps = {
  item: {},
};

const StyledAccordionTitle = styled(Accordion.Title)`
  &&& {
    ${({ disabled }) => disabled && `opacity: ${svars.disabledOpacity};`}
  }
`;

function HierarchicalParentNode({
  item,
  disabled,
  activeKeys,
  isItemActive,
  isItemSelected,
  selectedItems,
  isSelectedItem,
  isSelectedParent,
  marginLeft,
  onItemClick,
}) {
  return (
    <Accordion
      style={{
        background: 'inherit',
        padding: 0,
        margin: 0,
        boxShadow: 'none',
      }}
    >
      <StyledAccordionTitle
        index={0}
        active={isItemActive}
        style={{ color: svars.accentColor, padding: 0 }}
        onClick={
          !disabled ? onItemClick(item, isItemActive, isItemSelected) : null
        }
        disabled={disabled}
        data-testid="bo-select-element-accordion-menu"
      >
        <Icon name="dropdown" />
        {isItemSelected ? (
          <Icon
            id="remove"
            name="minus square outline"
            style={{
              color: svars.accentColor,
              fontSize: svars.fontSizeLarge,
            }}
          />
        ) : (
          <Icon
            id="add"
            name="plus square outline"
            style={{
              color: svars.fontColorBase,
              fontSize: svars.fontSizeLarge,
            }}
          />
        )}
        <span>
          <NestedCheckboxLabel item={item} />
        </span>
      </StyledAccordionTitle>
      <Accordion.Content active={isItemActive} style={{ padding: 0 }}>
        <NestedItems
          items={item.children || []}
          disabled={disabled}
          activeKeys={activeKeys}
          selectedItems={selectedItems}
          isSelectedItem={isSelectedItem}
          isSelectedParent={isSelectedParent}
          marginLeft={marginLeft + TAB_SIZE}
          onItemClick={onItemClick}
        />
      </Accordion.Content>
    </Accordion>
  );
}

HierarchicalParentNode.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  disabled: PropTypes.bool,
  activeKeys: PropTypes.arrayOf(PropTypes.string),
  isItemActive: PropTypes.bool,
  isItemSelected: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
  ),
  isSelectedItem: PropTypes.func,
  isSelectedParent: PropTypes.func,
  marginLeft: PropTypes.number,
  onItemClick: PropTypes.func,
};

HierarchicalParentNode.defaultProps = {
  item: {},
  disabled: false,
  activeKeys: [],
  isItemActive: false,
  isItemSelected: false,
  selectedItems: [],
  isSelectedItem: () => false,
  isSelectedParent: () => false,
  marginLeft: 0,
  onItemClick: () => null,
};

const addItemsOrChildren =
  (isSelectedItem) => (toSelect, item, filterItems) => {
    if (item.children) {
      item.children.map((childItem) =>
        addItemsOrChildren(isSelectedItem)(toSelect, childItem, filterItems)
      );
    } else if (!filterItems || !isSelectedItem(item, filterItems)) {
      toSelect.push(item);
    }
  };

export function HierarchicalCheckboxListItems({
  items,
  selectedItems,
  onSelectItem,
  onUnselectItem,
  onSelectItems,
  onUnselectItems,
  disabled,
  isSelectedItem,
  getItemParent,
}) {
  const [activeKeys, setActiveKeys] = useState([]);

  const nestedItems = useMemo(
    () => nestItems(items, getItemParent),
    [items, getItemParent]
  );
  const isSelectedParent = useCallback(
    (nestedItem) =>
      nestedItem.children.some((nestedChild) =>
        nestedChild.children
          ? isSelectedParent(nestedChild)
          : isSelectedItem(nestedChild, selectedItems)
      ),
    [selectedItems, isSelectedItem]
  );
  const onItemClick = useCallback(
    (item, isActive, isSelected) => (e) => {
      e.preventDefault();
      if (!isHierarchicalParent(item)) {
        if (isSelected) {
          onUnselectItem(item);
        } else {
          onSelectItem(item);
        }
      } else if (e.target.tagName === 'I' && e.target.id === 'add') {
        const toAdd = [];
        addItemsOrChildren(isSelectedItem)(toAdd, item, selectedItems);
        onSelectItems(toAdd);
      } else if (e.target.tagName === 'I' && e.target.id === 'remove') {
        const toRemove = [];
        addItemsOrChildren(isSelectedItem)(toRemove, item);
        onUnselectItems(toRemove);
      } else {
        setActiveKeys(
          isActive
            ? activeKeys.filter((activeKey) => activeKey !== item.key)
            : [...activeKeys, item.key]
        );
      }
    },
    [
      selectedItems,
      onSelectItem,
      onUnselectItem,
      onSelectItems,
      onUnselectItems,
      isSelectedItem,
      activeKeys,
    ]
  );
  return (
    <NestedItems
      items={nestedItems}
      disabled={disabled}
      selectedItems={selectedItems}
      isSelectedItem={isSelectedItem}
      activeKeys={activeKeys}
      isSelectedParent={isSelectedParent}
      onItemClick={onItemClick}
    />
  );
}

HierarchicalCheckboxListItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  selectedItems: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
  ),
  onSelectItem: PropTypes.func,
  onUnselectItem: PropTypes.func,
  onSelectItems: PropTypes.func,
  onUnselectItems: PropTypes.func,
  disabled: PropTypes.bool,
  isSelectedItem: PropTypes.func,
  getItemParent: PropTypes.func,
};

HierarchicalCheckboxListItems.defaultProps = {
  items: [],
  selectedItems: [],
  onSelectItem: () => null,
  onUnselectItem: () => null,
  onSelectItems: () => null,
  onUnselectItems: () => null,
  disabled: false,
  isSelectedItem: (item, selectedItems) =>
    selectedItems.some(({ id }) => id === item.key),
  getItemParent: (item) => item.parent,
};

export const onSelectAllHierarchicalFilteredItems =
  (
    filteredItems,
    selectedItems,
    onSelectItems,
    isSelectedItem,
    getItemParent
  ) =>
  () => {
    const toAdd = [];
    nestItems(filteredItems, getItemParent).forEach((item) =>
      addItemsOrChildren(isSelectedItem)(toAdd, item, selectedItems)
    );
    onSelectItems(toAdd);
  };
