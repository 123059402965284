import reactStringReplace from 'react-string-replace';

import { Trans, msg } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';

import { removeHtmlTags } from 'utils/helpers';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';
import { fontColorLighter } from 'assets/style/variables';

export const LightText = styled.i`
  color: ${fontColorLighter};
`;

export const LimitedTextCell = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const anonymizationTypeIconMap = {
  ADDRESS: { icon: '🏠', label: msg({ id: 'address' }) },
  DATE_TIME: { icon: '📅', label: msg({ id: 'date-time' }) },
  EMAIL_ADDRESS: { icon: '📧', label: msg({ id: 'email-address' }) },
  PERSON: { icon: '👤', label: msg({ id: 'person' }) },
  PHONE_NUMBER: { icon: '📞', label: msg({ id: 'phone-number' }) },
  LOCALISATION: { icon: '🏠', label: msg({ id: 'localisation' }) },
  CONTRACT: { icon: '📄', label: msg({ id: 'contract' }) },
  REFERENCE: { icon: '🆔', label: msg({ id: 'reference' }) },
  BP: { icon: '🆔', label: msg({ id: 'identifier' }) },
  INFO_BANK: { icon: '🏦', label: msg({ id: 'bank-info' }) },
  EMAIL: { icon: '📧', label: msg({ id: 'email' }) },
  PDL: { icon: '🆔', label: msg({ id: 'identifier' }) },
  FACTURATION_ACCOUNT: {
    icon: '🆔',
    label: msg({ id: 'facturation-account' }),
  },
  PCE: { icon: '🆔', label: msg({ id: 'identifier' }) },
};

export const displayAnonymizedElements = (text) =>
  text
    ? reactStringReplace(
        text,
        /(?:<|__)([A-Z](?:[A-Z_]+)?[A-Z])(?:>|__)/g,
        (match, i) => {
          const element = anonymizationTypeIconMap[match];
          return (
            <HelpTooltip
              key={i}
              help={
                <>
                  <Trans render={capitalizedTranslation} id={element?.label} />{' '}
                  (
                  <Trans id="anonymized" />)
                </>
              }
              baseColor
              compact
              basic
              trigger={
                <span style={{ margin: `0 ${svars.spaceSmall}` }}>
                  {element?.icon}
                </span>
              }
            />
          );
        }
      )
    : text;

const BaseLimitedRichTextCell = styled(LimitedTextCell)`
  display: inline-flex;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  & * {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function LimitedRichTextCell({ value, ...props }) {
  // Make sure that only one of dangerouslySetInnerHTML and children is set
  const allProps = value
    ? {
        ...props,
        dangerouslySetInnerHTML: { __html: removeHtmlTags(value) },
        children: null,
      }
    : props;
  return <BaseLimitedRichTextCell {...allProps} />;
}
LimitedRichTextCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default LightText;
