import PropTypes from 'prop-types';
import styled from 'styled-components';

import { readableColor } from 'polished';

import { getInterpolatedColor } from 'utils/colors';
import { floatFormatter } from 'utils/formatter';

import * as styleVariables from 'assets/style/variables';

const SentimentCellContainer = styled.div`
  margin: auto;
  min-width: ${styleVariables.sentimentCellMinWidth};
  max-width: ${styleVariables.sentimentCellMaxWidth};
  padding: ${styleVariables.spaceSmall} ${styleVariables.spaceNormal};
  background-color: ${({ color }) => color};
  font-weight: ${styleVariables.fontWeightMedium};
  height: 27px;
  border-radius: ${styleVariables.borderRadius};
  transition: all 0.2s ease-out;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  align-self: center;
  box-shadow: ${styleVariables.baseBoxShadow};
  line-height: ${styleVariables.lineHeightSmall};
  color: ${({ color }) =>
    readableColor(
      color,
      styleVariables.fontColorBase,
      styleVariables.colorWhite,
      true
    )};
`;

function SentimentCell({ sentiment }) {
  const interpolatedColor =
    (sentiment && getInterpolatedColor(sentiment)) ||
    styleVariables.absoluteMidColor;
  return (
    <SentimentCellContainer
      color={interpolatedColor}
      data-testid="sentiment-value"
    >
      {sentiment !== undefined ? floatFormatter(sentiment) : '-'}
    </SentimentCellContainer>
  );
}

SentimentCell.propTypes = { sentiment: PropTypes.number };
SentimentCell.defaultProps = { sentiment: undefined };

export default SentimentCell;
