import { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BubbleMenu, Editor } from '@tiptap/react';

import * as svars from 'assets/style/variables';

import {
  RichTextLinkEdition,
  RichTextPopoverContent,
} from './RichTextCustomizationElements';

const StyledBubbleMenu = styled(BubbleMenu)`
  background: ${svars.colorSecondary};
  color: white;
  border-radius: 8px;
  padding: 5px;
  margin: -5px;
  display: flex;

  .icon {
    border-radius: 5px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    &.disabled {
      pointer-events: none;
    }
  }

  .icon:hover {
    background: #555555;
    color: #ffffff;
    cursor: pointer;
  }

  .icon + .icon {
    margin-left: 4px;
  }
`;

function RichTextPopover({
  editor,
  withLink,
  withTextAlign,
  withHeading,
  withCodeBlock,
}) {
  const [linkPopoverVisible, setRichTextLinkEditionVisible] = useState(false);
  const onToggleRichTextLinkEdition = () =>
    setRichTextLinkEditionVisible(!linkPopoverVisible);

  const onClickAction = useCallback(
    (localEditor, action, actionParams) => () => {
      localEditor.chain().focus()[action]?.(actionParams).run();
    },
    []
  );

  const closeLinkEditionOnHide = useCallback(() => {
    setRichTextLinkEditionVisible(false);
  }, []);

  return (
    <StyledBubbleMenu
      tippyOptions={{ duration: 200, onHide: closeLinkEditionOnHide }}
      className="Popover"
      editor={editor}
    >
      {linkPopoverVisible ? (
        <RichTextLinkEdition
          editor={editor}
          onClose={onToggleRichTextLinkEdition}
        />
      ) : (
        <RichTextPopoverContent
          editor={editor}
          onClickAction={onClickAction}
          linkPopoverVisible={linkPopoverVisible}
          setRichTextLinkEditionVisible={setRichTextLinkEditionVisible}
          withTextAlign={withTextAlign}
          withHeading={withHeading}
          withCodeBlock={withCodeBlock}
          withLink={withLink}
        />
      )}
    </StyledBubbleMenu>
  );
}

RichTextPopover.propTypes = {
  editor: PropTypes.instanceOf(Editor).isRequired,
  withLink: PropTypes.bool,
  withTextAlign: PropTypes.bool,
  withHeading: PropTypes.bool,
  withCodeBlock: PropTypes.bool,
};

RichTextPopover.defaultProps = {
  withLink: false,
  withTextAlign: false,
  withHeading: false,
  withCodeBlock: false,
};

export default RichTextPopover;
