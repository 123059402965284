import { useCallback, useEffect, useRef, useState } from 'react';
import {
  DayPicker,
  useDayPicker,
  useInput,
  useNavigation,
} from 'react-day-picker';

import { Trans, t } from '@lingui/macro';
import { max as getMaxDate } from 'date-fns';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { fnsLocales } from 'actions/locale';
import { formatDate } from 'reducers/locale';

import { LightLargeHeader } from 'components/ui/Header';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';
import { range } from 'utils/helpers';
import { useOnClickOutside } from 'utils/hooks';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import 'react-day-picker/dist/style.css';

import Link from '../Link';

const INPUT_DATE_FORMAT = 'P';

const StyledInput = styled(TextInput)`
  & * {
    cursor: pointer;
  }
  &&&& {
    padding: 0;
    text-align: center;
    background: transparent;
    // Make sure "Date maximum" as placeholder fits
    min-width: 135px;
    // Override default max-width
    max-width: 135px;

    & input {
      max-width: 135px;
    }
    ${({ error }) =>
      error
        ? ` &&& {
    color: ${svars.colorDanger};
    border-color: ${svars.colorDanger};
  }
  `
        : ''}
  }
`;
export const StyledDayPicker = styled(DayPicker)`
  min-height: 282px;
  margin: auto;
  --rdp-cell-size: ${({ cellSize }) => cellSize || '33px'};
  --rdp-accent-color: ${({ error }) =>
    error ? 'red' : svars.accentColorClear};
  --rdp-background-color: #e7edff;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);

  & .rdp-table {
    margin: ${svars.spaceNormal};
  }
`;

export function CustomDayPickerCaption({ displayMonth }) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();
  const { fromDate, toDate } = useDayPicker();
  const authorizedYears = range(
    fromDate?.getFullYear(),
    (toDate || new Date()).getFullYear() + 1
  );
  const goToYear = useCallback(
    (e, { value }) => {
      const targetDate = new Date(displayMonth).setFullYear(value);
      goToMonth(getMaxDate([targetDate, fromDate]));
    },
    [displayMonth, goToMonth]
  );
  const onlyOneYear = !authorizedYears || authorizedYears.length === 1;
  return (
    <LightLargeHeader
      style={{
        padding: 0,
        paddingLeft: svars.spaceMedium,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `solid ${svars.colorLighterGrey}`,
      }}
    >
      <span>
        {formatDate(displayMonth, 'MMMM')}
        {onlyOneYear ? (
          <span
            style={{
              paddingLeft: svars.spaceNormal,
              color: svars.accentColor,
            }}
          >
            {displayMonth.getFullYear()}
          </span>
        ) : (
          <InTextDropdown
            options={authorizedYears.map((it) => ({
              key: it,
              text: `${it}`,
              value: it,
            }))}
            onChange={goToYear}
            value={displayMonth.getFullYear()}
            style={{
              paddingLeft: svars.spaceNormal,
            }}
          />
        )}
      </span>
      <span>
        <AnalyticsAwareHoverableIconButtonWithTooltip
          gaCategory="Dashboard - monitoring"
          gaAction="select previous month"
          help={t({ id: "previous-month" })}
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
          name="chevron left"
          accent="true"
          size={null}
        />
        <AnalyticsAwareHoverableIconButtonWithTooltip
          gaCategory="Dashboard - monitoring"
          gaAction="select next month"
          help={t({ id: "next-month" })}
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
          name="chevron right"
          accent="true"
          size={null}
        />
      </span>
    </LightLargeHeader>
  );
}

CustomDayPickerCaption.propTypes = {
  displayMonth: commonPropTypes.date.isRequired,
};

function DaySelect({
  selected,
  onSelect,
  footer,
  placeholder,
  error,
  disabled,
  fromDate,
  toDate,
  onTogglePopup,
  layout,
  ...otherProps
}) {
  const inputRef = useRef();
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const togglePopup = useCallback(() => {
    if (!disabled) {
      setPopupIsOpen(!popupIsOpen);
      if (onTogglePopup) onTogglePopup(!popupIsOpen);
    }
  }, [popupIsOpen, disabled, onTogglePopup]);
  const popupRef = useOnClickOutside(() => popupIsOpen && togglePopup());
  const onDaySelect = useCallback(
    (value) => {
      onSelect(value);
      togglePopup();
    },
    [onSelect, togglePopup]
  );

  // This creates a new instance of day picker props, that are merged with the
  // ones from the hook.
  // We use a useEffect hook herebelow to synchronize the selected date from
  // the day picker with the one from the input.
  const { inputProps, dayPickerProps } = useInput({
    locale: fnsLocales[window.__locale__],
    defaultSelected: selected,
    selected,
    fromYear: fromDate?.getFullYear(),
    toYear: toDate?.getFullYear(),
    format: INPUT_DATE_FORMAT,
    required: true,
  });
  useEffect(() => {
    if (dayPickerProps?.selected && dayPickerProps?.selected !== selected) {
      onDaySelect(dayPickerProps?.selected);
    }
  }, [dayPickerProps?.selected]);

  const resetSelectedValue = useCallback(() => {
    onSelect(null);
    // We need to manually call onChange on input to clear it
    inputProps.onChange({ value: '', target: { value: '' } });
  }, [onSelect]);
  return (
    <span
      style={{
        display: 'flex',
        flexDirection: layout,
        alignItems: layout === 'row' ? 'center' : 'flex-start',
      }}
    >
      <Popup
        on="click"
        open={!disabled && popupIsOpen}
        flowing
        style={{
          minHeight: '306px',
          background: error ? svars.colorDangerLightest : undefined,
          color: error ? svars.colorDanger : svars.fontColorBase,
        }}
        trigger={
          <span>
            <StyledInput
              ref={inputRef}
              error={error}
              disabled={disabled}
              onClick={togglePopup}
              icon="calendar"
              {...inputProps}
              placeholder={placeholder}
            />
          </span>
        }
      >
        <span ref={popupRef}>
          <StyledDayPicker
            locale={fnsLocales[window.__locale__]}
            components={{
              Caption: CustomDayPickerCaption,
            }}
            error={error}
            mode="single"
            selected={selected}
            onSelect={onDaySelect}
            footer={footer}
            fromDate={fromDate}
            toDate={toDate}
            {...otherProps}
            {...dayPickerProps}
          />
        </span>
      </Popup>
      {selected ? (
        <Link
          base
          onClick={resetSelectedValue}
          style={{ marginLeft: svars.spaceNormalLarge }}
        >
          <Trans render={capitalizedTranslation} id="reset" />
        </Link>
      ) : null}
    </span>
  );
}

DaySelect.propTypes = {
  selected: PropTypes.shape({ toLocaleDateString: PropTypes.func }),
  onSelect: PropTypes.func.isRequired,
  footer: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  fromDate: commonPropTypes.date,
  toDate: commonPropTypes.date,
  // Triggers when the dayselect popup is opened or closed
  onTogglePopup: PropTypes.func,
  layout: PropTypes.oneOf(['row', 'column']),
};
DaySelect.defaultProps = {
  footer: null,
  placeholder: formatDate(new Date(), INPUT_DATE_FORMAT),
  selected: null,
  error: false,
  disabled: false,
  fromDate: new Date('2017-01-01'),
  toDate: new Date(),
  onTogglePopup: undefined,
  layout: 'row',
};
export default DaySelect;
