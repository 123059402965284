import { useCallback, useEffect, useMemo, useState } from 'react';

const useAlternativeDataSelectorPortal = (data, meta) => {
  if (!(meta.dataAlternativeOptions && meta.alternativeValueNodeId)) return {};
  const options =
    typeof meta.dataAlternativeOptions === 'function'
      ? meta.dataAlternativeOptions(data)
      : meta.dataAlternativeOptions;
  const [displayedKey, setDisplayedKey] = useState(options?.[0]?.value || null);

  const onSelectAlternative = useCallback(
    (e, target) => {
      setDisplayedKey(target.value);
    },
    [displayedKey, data, meta]
  );
  useEffect(() => {
    // Reset displayed key if data is updated and displayed key is not set by default
    // this is used when alternative option list is dynamic (form fields, ...)
    if (data && !displayedKey) {
      onSelectAlternative(null, { value: options?.[0]?.value || null });
    }
  }, [data]);
  return useMemo(
    () => ({
      dropdownProps: {
        options,
        onChange: onSelectAlternative,
        value: displayedKey,
        nodeId: meta.alternativeValueNodeId,
      },
      chartProps:
        (data &&
          displayedKey &&
          meta.getChartProps(data, { options, value: displayedKey }, meta)) ||
        null,
    }),
    [
      options,
      onSelectAlternative,
      displayedKey,
      meta.alternativeValueNodeId,
      meta.cellKey,
      data,
    ]
  );
};

export default useAlternativeDataSelectorPortal;
