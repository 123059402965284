/* eslint-disable no-bitwise */
import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  ENTITIES_COLORS,
  avatarFontSize,
  avatarSize,
  colorWhite,
} from 'assets/style/variables';

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledAvatar = styled.div`
  background-color: ${(props) => props.bgColor};
  color: ${colorWhite};
  width: ${avatarSize};
  height: ${avatarSize};
  font-size: ${avatarFontSize};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const hashStringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str?.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const index = Math.abs(hash) % ENTITIES_COLORS.length;
  return ENTITIES_COLORS[index];
};

export function ProfileImage({ name }) {
  const [bgColor, setBgColor] = useState(hashStringToColor(name));
  useEffect(() => {
    setBgColor(hashStringToColor(name));
  }, [name]);

  const nameParts = name?.split(' ');
  const firstNameInitial = nameParts?.[0]?.[0]?.toUpperCase() || '';
  const lastNameInitial = nameParts?.[1]?.[0]?.toUpperCase() || '';
  const initials = `${firstNameInitial}${lastNameInitial}`;

  return (
    <AvatarContainer>
      <StyledAvatar bgColor={bgColor}>{initials}</StyledAvatar>
    </AvatarContainer>
  );
}

ProfileImage.propTypes = {
  name: PropTypes.string,
};

ProfileImage.defaultProps = {
  name: '',
};
