import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import moment from 'moment';

import * as styleVariables from 'assets/style/variables';

export const smallIconMargin = '3px';

const hoverableIconStyle = css`
  pointer-events: auto;
  cursor: pointer;
  font-size: ${styleVariables.fontSizeXLarge};
  color: ${(props) =>
    props.disabled ? styleVariables.fontColorDisabled : 'inherit'};
  transition: ${styleVariables.transitionBase};
  &:hover {
    transform: ${(props) => (props.disabled ? 'null' : 'scale(1.15)')};
  }
`;
export const HoverableIcon = styled(Icon)`
  &&& {
    ${hoverableIconStyle};
  }
`;
HoverableIcon.Group = styled(Icon.Group)`
  &&& {
    ${hoverableIconStyle};
  }
`;

export const compileExportFilename = (facetName, exportName) => {
  const now = new Date();
  let fileName = `Better World - ${moment(now).format(
    'YYYYMMDD HH-mm'
  )} - ${facetName}`;
  if (exportName) {
    fileName = `${fileName} - ${
      typeof exportName === 'function' ? exportName() : exportName
    }`;
  }
  return fileName;
};
