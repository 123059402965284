import { Toaster as HotToaster, ToastBar, toast } from 'react-hot-toast';

import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

import Header from 'components/ui/Header';
import { HoverableIconButton } from 'components/ui/icon/HoverableIcon';

import * as svars from 'assets/style/variables';

const TOAST_OPTIONS = {
  duration: 3000,
  style: {
    background: svars.colorSecondary,
    color: svars.colorWhite,
  },

  // Default options for specific types
  success: {
    theme: {
      primary: svars.colorSuccess,
    },
  },
  error: {
    theme: {
      primary: svars.colorDanger,
    },
  },
};

const toastFactory = (toastInstance) => (
  <ToastBar toast={toastInstance}>
    {({ icon, message }) => (
      <>
        {icon}
        {message}
        {toastInstance.type !== 'loading' && (
          <HoverableIconButton
            name="close"
            onClick={() => toast.dismiss(toastInstance.id)}
            style={{
              marginBottom: 'auto',
              padding: svars.spaceSmaller,
            }}
          />
        )}
      </>
    )}
  </ToastBar>
);

function ToasterBody({ title, message }) {
  return (
    <Header style={{ color: 'white' }}>
      {title}
      {message ? (
        <Header.Subheader style={{ color: 'inherit' }}>
          {message}
        </Header.Subheader>
      ) : null}
    </Header>
  );
}

ToasterBody.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
ToasterBody.defaultProps = {
  message: null,
};

function Toaster() {
  return (
    <HotToaster
      position="bottom-right"
      reverseOrder={false}
      gutter={8}
      containerStyle={{ zIndex: 9999 }}
      toastOptions={TOAST_OPTIONS}
    >
      {toastFactory}
    </HotToaster>
  );
}

const pushToasterFactory = (status, options) => (title, message) => {
  (
    (status === 'success' && toast.success) ||
    (status === 'error' && toast.error) ||
    toast
  )(<ToasterBody title={title} message={message} />, options);
};

export const pushSuccessToaster = pushToasterFactory('success');
export const pushDangerToaster = pushToasterFactory('error');
export const pushWarningToaster = pushToasterFactory('warning', {
  icon: (
    <Icon
      size="large"
      name="warning sign"
      style={{ color: svars.colorYellowWarning }}
    />
  ),
  iconTheme: {
    primary: svars.colorWarning,
    secondary: 'white',
  },
});

export default Toaster;
