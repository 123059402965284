

import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonSecondaryAccent } from 'components/ui/button';

import commonPropTypes from 'utils/commonPropTypes';
import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

const Container = styled.div`
  white-space: pre;
`;

const SwitchButtonSecondaryAccent = styled(ButtonSecondaryAccent)`
  &&&& {
    min-width: 2.5rem;
    padding: 0.4rem;
    border-radius: ${({ last, first }) =>
      (last && `0px ${svars.ctaBorderRadius} ${svars.ctaBorderRadius} 0px`) ||
      (first && `${svars.ctaBorderRadius} 0px  0px ${svars.ctaBorderRadius}`) ||
      '0px'};
    margin: 0;
    min-height: 1rem;
    font-size: ${svars.fontSizeSmall};
    background-image: none !important;
    background: ${({ active }) =>
      (active && svars.accentColor) || svars.accentColorLighter};

    &:hover {
      ${({ active }) =>
        active
          ? ``
          : `background: ${svars.accentColorMedium};
       color: ${svars.accentColor};`}
    }
  }
`;
function SwitchGroup({ items, value, onChange, disabled, style }) {
  const nItems = items.length;
  return (
    <Container style={style}>
      {items.map((item, i) => {
        const isActive = item.value === value;
        return (
          <SwitchButtonSecondaryAccent
            key={`it-lab-${item.key}`}
            onClick={() => !isActive && onChange(item.value)}
            active={!!isActive}
            disabled={disabled}
            first={i === 0 ? 'true' : undefined}
            last={i === nItems - 1 ? 'true' : undefined}
          >
            {item.i18nLabel ? (
              <Trans id={item.i18nLabel} />
            ) : (
              capitalize(item.label)
            )}
          </SwitchButtonSecondaryAccent>
        );
      })}
    </Container>
  );
}

const valuePropTypes = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.object,
  PropTypes.bool,
]);
SwitchGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      i18nLabel: commonPropTypes.i18nText,
      // We can use object value to map multiple fields to a given value
      value: valuePropTypes,
    })
  ).isRequired,
  value: valuePropTypes,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: commonPropTypes.style,
};

SwitchGroup.defaultProps = {
  value: '',
  disabled: false,
  style: {},
};

export default SwitchGroup;
