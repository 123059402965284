import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';

import * as svars from 'assets/style/variables';

// eslint-disable-next-line import/prefer-default-export
export const StyledTabPane = styled(Tab.Pane)`
  &&& {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: ${svars.spaceMedium};
    border-radius: ${svars.borderRadius};
  }
`;
