import { useCallback, useEffect, useState } from 'react';

import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LightHeader } from 'components/ui/Header';
import { AnalyticsAwareHoverableIconButtonWithTooltip } from 'components/ui/icon/HoverableIcon';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';
import { useDebounce } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import ErrorMessage from '../ErrorMessage';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const LabelContainer = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: center;
  margin-bottom: ${svars.spaceNormal};
`;

function CustomizationInput({
  label,
  validate,
  onChange,
  value,
  errorMessage,
  resetValue,
  initialValue,
  placeholder,
  style,
  InputComponent,
  ...inputProps
}) {
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState(null);
  const debouncedValue = useDebounce(inputValue, 800);
  const undoValueChange = useCallback(() => {
    if (inputValue !== initialValue) {
      setInputValue(initialValue);
    }
    resetValue();
    if (error) setError(null);
  }, [initialValue, inputValue]);

  useEffect(() => {
    if (value && value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (inputValue === '') {
      if (error) setError(null);
      return;
    }
    if (validate) {
      const validated = validate(inputValue);
      if (validated === true) {
        if (error) setError(null);
      } else {
        setError(validated === false ? true : validated);
        return;
      }
    }
    if (inputValue !== value && inputValue !== '') {
      onChange(inputValue);
    }
  }, [debouncedValue]);
  return (
    <Container style={style}>
      {label ? (
        <LabelContainer>
          <LightHeader
            content={<Trans id={label} />}
            style={{
              margin: `0 ${svars.spaceMediumLarge} 0 0`,
              marginRight: svars.spaceMediumLarge,
            }}
          />
          <AnalyticsAwareHoverableIconButtonWithTooltip
            name="undo"
            disabled={inputValue === initialValue}
            onClick={undoValueChange}
            help={t({ id: 'reset-to-published-campaign-value' })}
          />
        </LabelContainer>
      ) : null}
      <InputComponent
        style={{ width: '100%' }}
        value={inputValue}
        onChange={(e, { value: newValue }) => setInputValue(newValue)}
        error={error}
        placeholder={placeholder}
        {...inputProps}
      />
      {errorMessage && <ErrorMessage message={errorMessage} show={error} />}
    </Container>
  );
}

CustomizationInput.propTypes = {
  label: commonPropTypes.i18nText,
  validate: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
  resetValue: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.shape({}),
  InputComponent: PropTypes.elementType,
};

CustomizationInput.defaultProps = {
  label: null,
  validate: null,
  errorMessage: null,
  initialValue: '',
  placeholder: null,
  style: {},
  InputComponent: TextInput,
};

export default CustomizationInput;
